import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useTypeExpertises() {
  const { errorToast, successToast } = useAlertNotification()
  const typeExpertise = ref([])
  const loader = ref(false)
  const typeExpertiseProcess = ref(false)
  const typeExpertiseSuccess = ref(false)
  const typeExpertises = ref([])
  const errors = ref('')

  // Liste des type_expertises
  const getTypeExpertises = async () => {
    loader.value = true
    await axiosClient.get('/type_expertises').then(response => {
      if (response.data.success === true) {
        loader.value = false
        typeExpertises.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir un typeExpertise
  const getTypeExpertise = async slug => {
    const response = await axiosClient.get(`/type_expertises/${slug}`)
    typeExpertise.value = response.data.data
  }
  // Ajouter une typeExpertise
  const createTypeExpertise = async data => {
    errors.value = ''
    typeExpertiseProcess.value = true
    await axiosClient.post('/type_expertises', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          typeExpertiseSuccess.value = true
          typeExpertiseProcess.value = false
          getTypeExpertises()
        }
      })
      .catch(error => {
        typeExpertiseProcess.value = false
        typeExpertiseSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errors.value = error.response.data.errors
          const responseErrors = error.response.data.errors
          Object.keys(responseErrors).forEach(key => {
            errorToast('Oups erreur !', responseErrors[key][0])
          })
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une typeExpertise
  const updateTypeExpertise = async data => {
    errors.value = ''
    typeExpertiseProcess.value = true
    await axiosClient.patch(`/type_expertises/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          typeExpertiseSuccess.value = true
          typeExpertiseProcess.value = false
          getTypeExpertises()
        }
      })
      .catch(error => {
        typeExpertiseProcess.value = false
        typeExpertiseSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    typeExpertise,
    typeExpertises,
    getTypeExpertises,
    typeExpertiseProcess,
    createTypeExpertise,
    updateTypeExpertise,
    getTypeExpertise,
    loader,
    typeExpertiseSuccess,
  }
}
